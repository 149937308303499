import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Video = makeShortcode("Video");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const Button = makeShortcode("Button");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-transform-the-internet",
      "style": {
        "position": "relative"
      }
    }}>{`🖌️ Transform the internet`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-transform-the-internet",
        "aria-label": "️ transform the internet permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`These two short video essays start off our curated materials because we need to shift the hour long
norm we've been setting. Sit back, relax, and enjoy for a moment the change of pace.`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Video src="https://www.youtube-nocookie.com/embed/783hwpJTjlo" mdxType="Video" />
      <Video src="https://www.youtube-nocookie.com/embed/uXUK9WpwmIs" mdxType="Video" />
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Art is that which gets us to question value and meaning most deeply. This video - itself a
series of artful expressions cut together with digital tools - describes a number of interesting
pieces which would not be possible without the internet. It reveals the enormity of what we can craft with
careful intention, attention, and a deep appreciation for the meaning of our media environments.`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Art begins with work which throws a veil over the invisible so its outline may be seen. It is
brought to completion in the moment of encounter with an other. That is, art is not created as
you are painting, or writing, or composing, or sculpting, or coding. It `}<em parentName="p">{`occurs`}</em>{` when your work
brings someone else to experience a state of consciousness they would not have otherwise known.`}</p>
    <p>{`At its best, art transmits what cannot be said, or sung, or heard, or felt.`}</p>
    <p>{`We have always used technology to achieve this. For instance, the invention of
metal `}<a parentName="p" {...{
        "href": "https://primetimepaint.ca/a-brief-history-of-the-paintbrush/"
      }}>{`ferrules`}</a>{`—the little bit
that connects the bristles to the shaft—led to the flat paint brushes so beloved by Impressionist painters, who used them to capture the effects of light in different environments. Technological innovations in the tools we use to express ourselves often result in significant artistic advances in sight, perception, philosophy and—ultimately—culture.`}</p>
    <p>{`We invite you to question what new pluralities networks of and for value—operating by means of a world wide web that enables light-speed communication—will allow us to envision.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Is art something we do or something we encounter?`}</p>
        <p>{`Both. Though the encounter leaves a more lasting impression.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Artistic advances in our ability to express ourselves are often the result of what?`}</p>
        <p>{`Technological advances in our tools.`}</p>
      </Card>
    </Flash>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Mother Earth Mother Board`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Image alt="Mother Earth Mother Board" src="/images/mod4-img/mother-earth-board.jpg" mdxType="Image" />
        </Aligner>
        <p>{`If you want to explore what we're actually throwing a veil over, this is as good a place to
start as any.`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.wired.com/1996/12/ffglass/" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Artificiality`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Image alt="Artificiality" src="/images/mod4-img/artificial.webp" mdxType="Image" />
        </Aligner>
        <p>{`Put the art back in artificiality with Werner Herzog. Search for "The Inner Chronicle of What We Are" if you enjoy this.`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.youtube.com/watch?v=ogFN6ncIaD8" mdxType="Button">
    Watch
          </Button>
        </Aligner>
      </Box>
    </Column>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=7gUh8j5ui0o" mdxType="Link">
        <p>{`The Art of life`}</p>
      </Link>
      <Link to="https://aeon.co/essays/art-like-loss-can-shock-us-into-our-authentic-self" mdxType="Link">
        <p>{`The quality of their expressive intention`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      